import React, { useState } from 'react';
import { Button } from '@rmwc/button';
import styled from '../styled-components';
import { DeviceLogs } from '../types/DeviceLogs';

type LogRowProps = { row: DeviceLogs };

interface LogEntries {
  name: string;
  value: string;
}

const StyledLogRow = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid lightgrey;
`;

const StyledLowRowData = styled.div`
  display: flex;
  flex-drection: row;
  background-color: white;
  margin-left: 20px;
`;

const StyledLowRowDetails = styled.div`
  background-color: #e8e8e8;
  padding: 5px;
  margin: 5px 5px 5px 15px
  border-radius: 5px;
`;

const LogTableCellLarge = styled.p`
  padding: 0;
  font-size: 0.75rem;
  font-weight: 500;
  margin: 0;
  flex: 1;
  align-self: center;
  text-align: left;
`;

const LogTableCellSmall = styled.p`
  padding: 0;
  font-size: 0.75rem;
  font-weight: 500;
  margin: 0;
  flex: 0.5;
  align-self: center;
  text-align: left;
`;

const LogDetailText = styled.p`
  font-size: 9pt;
  padding: 0 0 5px 0;
  font-size: 0.75rem;
  font-weight: 500;
  margin: 0;
`;

const StyledButton = styled(Button)`
  padding: 0px;
`;

function LogRow(props: LogRowProps) {
  const { row } = props;
  const [showDetails, setShowDetails] = useState(false);

  const createDetails = (details: string) => {
    const detailsJson = JSON.parse(details);
    return detailsJson.map((item: LogEntries) => {
      return (
        <LogDetailText>
          {item['name']} : {item['value']}
        </LogDetailText>
      );
    });
  };

  const detailsSwitch = () => {
    setShowDetails(!showDetails);
  };

  return (
    <StyledLogRow>
      <StyledLowRowData>
        <LogTableCellSmall>{row.type}</LogTableCellSmall>
        <LogTableCellLarge>{row.state}</LogTableCellLarge>
        <LogTableCellLarge>{row.timestamp}</LogTableCellLarge>
        <LogTableCellLarge>
          <StyledButton onClick={detailsSwitch}>{showDetails ? 'Hide Details' : 'Show Details'} </StyledButton>
        </LogTableCellLarge>
      </StyledLowRowData>
      {showDetails && <StyledLowRowDetails>{createDetails(row.details)}</StyledLowRowDetails>}
    </StyledLogRow>
  );
}

export default LogRow;
